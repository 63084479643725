$width-pc: 1100px;
$width-tb: 600px;
$width-iphone6: 375px;
$width-sp: 320px;
$color-main: #3AA4AF;
$color-green: #39A06E;
$color-yellow: #faaf3b;
$color-blue: #00528c;
$color-orange: #e28a35;
$color-program-orange: #ff9035;
$color-program-blue: #2d8cff;
$fw-regular: 400;
$fw-medium: 500;
$fw-bold: 600;
$fw-black: 900;

// #フォントサイズをremに変換
@mixin fz($size: 24, $base: 16) {
	font-size: $size + px;
	font-size: ($size / $base) * 1rem;
}
// #mediaquery
@mixin media-pc {
	@media only screen and (max-width: $width-pc) {
		@content;
	}
}
@mixin media-tb {
	@media only screen and (max-width: $width-tb) {
		@content;
	}
}
@mixin media-iphone6 {
	@media only screen and (max-width: $width-iphone6) {
		@content;
	}
}
@mixin media-sp {
	@media only screen and (max-width: $width-sp) {
		@content;
	}
}
// #clearfix
@mixin clearfix {
	&:after {
		display: block;
		visibility: hidden;
		clear: both;
		height: 0;
		content: '.';
	}
}
// #sprite画像を retina 対応
@mixin sprite-width($sprite) {
	width: nth($sprite, 5) / 2;
}
@mixin sprite-height($sprite) {
	height: nth($sprite, 6) / 2;
}
@mixin sprite-position($sprite) {
	$sprite-offset-x: nth($sprite, 3)/2;
	$sprite-offset-y: nth($sprite, 4)/2;
	background-position: $sprite-offset-x  $sprite-offset-y;
}
@mixin sprite-image($sprite) {
	$sprite-image: nth($sprite, 9);
	background-image: url(/common/images/sprite.png);
}
@mixin sprite-size($sprite) {
	background-size: nth($sprite, 7)/2 nth($sprite, 8)/2;
}
@mixin sprite($sprite) {
	@include sprite-image($sprite);
	@include sprite-position($sprite);
	@include sprite-width($sprite);
	@include sprite-height($sprite);
	@include sprite-size($sprite);
}
// #矢印
@mixin arrow($direction: right, $size: 10, $other-size: 10, $color: black){
	$other-1 : top;
	$other-2 : bottom;
	$set: left;
	@if $direction == left{
		$set: right;
		$other-1 : top;
		$other-2 : bottom;
	}@elseif $direction == top{
		$set: bottom;
	}@elseif $direction == bottom{
		$set: top;
	}@else{
		$set: left;
	}
	width: 0;
	height: 0;
	border: $size + px solid transparent;
	border-#{$other-1}-width: $other-size + px;
	border-#{$other-2}-width: $other-size + px;
	border-#{$set}-color: $color;
}

@mixin sp {
	@media screen and (max-width: 767px) {
		@content;
	}
}
