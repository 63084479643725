@charset 'UTF-8';
@import 'mixin';

.m-input_txt {
  font-size: 16px;
  padding: 0 20px;
  width: 100%;
  height: 60px;
  border: 2px solid #b5b5b5;
  border-radius: 3px;
  background-color: #fff;
  box-sizing: border-box;
  @include sp {
    padding: 0 10px;
  }
  &-other {
    width: 475px;
    height: 40px;
    margin-left: 10px;
    @include sp {
      width: 100%;
      margin-top: 5px;
      margin-left: 0;
    }
  }
}

.m-input_txtarea {
  font-size: 16px;
  padding: 10px 20px;
  box-sizing: border-box;
  width: 100%;
  border: 2px solid #b5b5b5;
  border-radius: 3px;
  background-color: #fff;
}

.m-select {
  width: 100%;
  color: #333;
  font-size: 16px;
  line-height: 1.5;
  padding: 15px 13px;
  border: 2px solid #b5b5b5;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #fff;
}

.m-section {
  &--ttl {
    font-size: 36px;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;
    @include sp {
      font-size: 24px;
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
}

.m-inner {
  width: 980px;
  box-sizing: border-box;
  padding: 0 15px;
  position: relative;
  margin: 0 auto;
  @include sp {
    width: 100%;
  }
}

.m-main_visual {
  display: flex;
  height: 320px;
  margin-top: 94px;
  @include sp {
    flex-wrap: wrap;
    height: auto;
    margin-top: 70px;
  }
  &-explore {
    .m-main_visual--bg {
      background-image: url('/course/images/bg-m-main-visual-explore.jpg');
    }
    .m-main_visual_about {
      background-image: url('/course/images/bg-m-main-visual-about-ptn-gr.png');
    }
    .m-main_visual_about--ttl {
      color: $color-green;
    }
  }
  &-break {
    .m-main_visual--bg {
      background-image: url('/course/images/bg-m-main-visual-break.jpg');
    }
    .m-main_visual_about {
      background-image: url('/course/images/bg-m-main-visual-about-ptn-or.png');
    }
    .m-main_visual_about--ttl {
      color: $color-orange;
    }
  }
  &-contact {
    .m-main_visual--bg {
      background-image: url('/contact/images/bg-m-main-visual-contact.jpg');
    }
    .m-main_visual_about {
      background-image: url('/common/images/bg-m-main-visual-about-ptn-main.png');
    }
    .m-main_visual_about--ttl {
      color: $color-main;
    }
  }
  &-briefing {
    .m-main_visual--bg {
      background-image: url('/briefing/images/bg-m-main-visual-briefing.jpg');
    }
    .m-main_visual_about {
      padding-right: 170px;
      background-image: url('/common/images/bg-m-main-visual-about-ptn-main.png');
      @include sp {
        padding-top: 15px;
        padding-right: 140px;
      }
    }
    .m-main_visual_about--ttl {
      font-size: 28px;
      color: $color-main;
      &-single_line {
        min-height: 84px;
        display: flex;
        align-items: center;
      }
      @include sp {
        font-size: 18px;
        &-single_line {
          min-height: 64px;
        }
      }
      &:after {
        border-width: 84px 30px 0 0;
        @include sp {
          border-width: 64px 20px 0 0;
        }
      }
    }
  }
  &-seminar {
    .m-main_visual--bg {
      background-image: url('/briefing/images/bg-m-main-visual-briefing.jpg');
    }
    .m-main_visual_about {
      padding-right: 170px;
      background-image: url('/common/images/bg-m-main-visual-about-ptn-main.png');
      @include sp {
        padding-top: 15px;
        padding-right: 140px;
      }
    }
    .m-main_visual_about--ttl {
      color: $color-main;
    }
    .m-main_visual_about--ttl_main {
      padding-left: calc(50vw - 550px);
      @include sp {
        padding-left: 15px;
      }
    }
  }
  &-interview {
    background: url('/interview/images/bg-m-main-visual-interview.jpg') no-repeat center center;
    background-size:cover;
    height: 271px;
    width: 100%;
    @include sp {
      height: 145px;
    }
  }
  &-privacy {
    height: 100px;
    background: url('/common/images/bg-m-main-visual-about-ptn-main.png') repeat top center;
    @include sp {
      height: 50px;
    }
  }
  &--bg {
    width: 50%;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: cover;
    position: relative;
    @include sp {
      width: 100%;
      height: 65vw;
      order: 0;
    }
  }
}

.m-main_visual_about {
  width: 50%;
  background-repeat: repeat;
  background-position: top left;
  padding-top: 115px;
  padding-right: 190px;
  @include sp {
    width: 100%;
    padding: 30px 40px 15px 0;
    order: 1;
  }
  &-break {
    padding-right: 110px;
    @include sp {
      padding-right: 40px;
    }
  }
  &--ttl {
    font-size: 32px;
    font-weight: $fw-bold;
    position: relative;
    background: #fff;
    padding-right: 10px;
    @include sp {
      font-size: 20px;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    &:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 48px 30px 0 0;
      border-color: #fff transparent transparent transparent;
      position: absolute;
      top: 0;
      right: -30px;
      @include sp {
        border-width: 40px 20px 0 0;
        right: -20px;
      }
    }
  }
  &--ttl_main {
    display: block;
    padding-left: calc(50vw - 500px);
    @include sp {
      padding-left: 15px;
    }
  }
  &--ttl_sub {
    font-size: 18px;
    font-weight: $fw-bold;
    color: #fff;
    position: absolute;
    top: -35px;
    left: calc(50vw - 500px);
    @include sp {
      font-size: 14px;
      top: -25px;
      left: 15px;
    }
  }
}

.m-course_info {
  padding-top: 25px;
  padding-bottom: 25px;
  background: #DDDDDD;
  @include sp {
    padding: 15px;
  }
  &--inner {
    max-width: 684px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &.single {
      justify-content: center;
    }
  }
}

.m-page_intro {
  padding-bottom: 55px;
  @include sp {
    padding-bottom: 30px;
  }
  &-explore {
    .m-page_intro {
      &--ttl {
        color: $color-green;
      }
      &--ttl_border {
        background: $color-green;
      }
      &--lead {
        &:before {
          border-top-color: $color-green;
          border-left-color: $color-green;
        }
        &:after {
          border-right-color: $color-green;
          border-bottom-color: $color-green;
        }
      }
    }
  }
  &-break {
    .m-page_intro {
      &--ttl {
        color: $color-orange;
      }
      &--ttl_border {
        background: $color-orange;
      }
      &--lead {
        &:before {
          border-top-color: $color-orange;
          border-left-color: $color-orange;
        }
        &:after {
          border-right-color: $color-orange;
          border-bottom-color: $color-orange;
        }
      }
    }
  }
  &-contact {
    padding-top: 55px;
    @include sp {
      padding-top: 30px;
    }
    .m-page_intro {
      &--ttl {
        color: $color-main;
      }
      &--ttl_border {
        background: $color-main;
      }
      &--lead {
        &:before {
          border-top-color: $color-main;
          border-left-color: $color-main;
        }
        &:after {
          border-right-color: $color-main;
          border-bottom-color: $color-main;
        }
      }
    }
  }
  &--ttl {
    font-size: 45px;
    text-align: center;
    position: relative;
    @include sp {
      font-size: 20px;
    }
  }
  &--ttl_border {
    content: '';
    width: 110px;
    height: 136px;
    display: block;
    position: absolute;
    top: 0;
    left: 120px;
    @include sp {
      left: 0;
      width: 40px;
      height: 60px;
    }
    &:before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 136px 84px 0 0;
      border-color: #fff transparent transparent transparent;
      position: absolute;
      top: 0;
      left: 0;
      @include sp {
        border-width: 60px 30px 0 0;
      }
    }
    &:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 136px 84px;
      border-color: transparent transparent #fff transparent;
      position: absolute;
      top: 0;
      right: 0;
      @include sp {
        border-width: 0 0 60px 30px;
      }
    }
    &-break {
      left: 40px;
      @include sp {
        left: 0px;
      }
    }
  }
  &--ttl_main {
    padding-top: 76px;
    display: block;
    @include sp {
      padding-top: 36px;
    }
    &-break {
      @include sp {
        padding-left: 20px;
      }
    }
  }
  &--txt {
    max-width: 740px;
    margin: 0 auto;
    font-size: 16px;
    line-height: 2;
    @include sp {
      line-height: 1.7;
    }
    &-briefing {
      text-align: center;
      margin-bottom: 56px;
      @include sp {
        font-size: 14px;
        text-align: left;
        margin-bottom: 40px;
      }
    }
    &-centering_without_sp {
      text-align: center;
      @include sp {
        text-align: left;
      }
    }
    &-note {
      display: block;
      margin-top: 16px;
      font-size: 14px;
      line-height: 1.5;
      @include sp {
        font-size: 12px;
      }
    }
    & > sup {
      font-size: 60%;
      position: relative;
      top: -5px;
    }
  }
  &--lead {
    font-size: 20px;
    font-weight: $fw-bold;
    line-height: 2;
    text-align: center;
    max-width: 740px;
    margin: 35px auto 0;
    padding-top: 30px;
    padding-bottom: 30px;
    position: relative;
    @include sp {
      font-size: 14px;
      line-height: 1.7;
      padding: 20px;
    }
    &:before {
      content: '';
      width: 100px;
      height: 77px;
      border-top: 1px solid #fff;
      border-left: 1px solid #fff;
      position: absolute;
      top: 0;
      left: 0;
      @include sp {
        width: 60px;
        height: 40px;
      }
    }
    &:after {
      content: '';
      width: 100px;
      height: 77px;
      border-right: 1px solid #fff;
      border-bottom: 1px solid #fff;
      position: absolute;
      right: 0;
      bottom: 0;
      @include sp {
        width: 60px;
        height: 40px;
      }
    }
    strong {
      font-size: 24px;
      color: $color-main;
      padding-left: 1px;
      padding-right: 1px;
      @include sp {
        font-size: 16px;
      }
    }
  }
  &--note {
    font-size: 16px;
    font-weight: normal;
    display: block;
    line-height: 1.7;
    margin-top: 10px;
    @include sp {
      font-size: 13px;
      padding-top: 10px;
    }
  }
  &--link {
    text-decoration: underline;
    transition: .15s;
    &:hover {
      color: $color-main;
    }
  }
}

.m-form_section {
  padding-bottom: 60px;
  background: #F5F5F5;
  &--ttl {
    font-size: 36px;
    text-align: center;
    position: relative;
    @include sp {
      font-size: 20px;
    }
  }
  &--ttl_border {
    content: '';
    width: 110px;
    height: 136px;
    display: block;
    background: $color-main;
    position: absolute;
    top: 0;
    left: 120px;
    @include sp {
      left: 0;
      width: 40px;
      height: 60px;
    }
    &:before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 136px 84px 0 0;
      border-color: #F5F5F5 transparent transparent transparent;
      position: absolute;
      top: 0;
      left: 0;
      @include sp {
        border-width: 60px 30px 0 0;
      }
    }
    &:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 136px 84px;
      border-color: transparent transparent #F5F5F5 transparent;
      position: absolute;
      top: 0;
      right: 0;
      @include sp {
        border-width: 0 0 60px 30px;
      }
    }
  }
  &--ttl_main {
    padding-top: 86px;
    display: block;
    @include sp {
      padding-top: 36px;
    }
  }
}

.l-form {
  margin-top: 40px;
  padding: 40px;
  background-color: #fff;
  position: relative;
  opacity: 0.2;
  pointer-events: none;
  @include sp {
    margin-top: 20px;
    padding: 20px 15px;
  }
  &.active {
    opacity: 1;
    pointer-events: auto;
  }
  &-confirm {
    .l-form--submit {
      margin-top: 60px;
      @include sp {
        margin-top: 30px;
      }
    }
  }
  &--ttl {
    color: $color-main;
    font-size: 24px;
    text-align: center;
    margin: 0 0 40px 0;
    @include sp {
      font-size: 18px;
      margin: 0 0 15px 0;
    }
  }
  &--txt {
    font-size: 16px;
    line-height: 1.7;
    margin-bottom: 40px;
    @include sp {
      font-size: 14px;
      margin-bottom: 20px;
    }
  }
  &--note {
    text-align: center;
    font-size: 16px;
    margin: 0 0 30px 0;
    @include sp {
      font-size: 12px;
      line-height: 1.3;
      margin: 0 0 15px 0;
    }
  }
  &--submit {
    margin-top: 20px;
    margin-bottom: 20px;
    p {
      text-align: center;
    }
    .m-btn {
      button,input {
        margin: 20px auto 0;
        border: 0;
        display: block;
        height: 85px;
        width: 415px;
        font-weight: bold;
        font-size: 30px;
        color: #fff;
        box-shadow: 0 10px 0 rgb(0, 128, 147);
        background: $color-main;
        transition: 0.2s;
        position: relative;
        &:hover {
          cursor: pointer;
          transform: translate(0,10px);
          box-shadow: none;
        }
        &:before {
          content: "";
          display: block;
          width: 30px;
          height: 30px;
          background-color: #fff;
          border-radius: 50%;
          position: absolute;
          right: 20px;
          top: calc(50% - 15px);
          @include sp {
            width: 20px;
            height: 20px;
            top: calc(50% - 10px);
            right: 15px;
          }
        }
        &:after {
          content: "";
          display: block;
          border-left: 3px solid $color-main;
          border-bottom: 3px solid $color-main;
          width: 8px;
          height: 8px;
          position: absolute;
          right: 31px;
          top: calc(50% - 6px);
          transform: rotate(-135deg);
          @include sp {
            width: 6px;
            height: 6px;
            top: calc(50% - 5px);
            right: 22px;
          }
        }
        @include sp {
          width: 100%;
          height: 52px;
          font-size: 18px;
        }
      }
    }
  }
  &--back {
    margin-top: 20px;
    margin-bottom: 20px;
    position: absolute;
    left: 50px;
    bottom: 70px;
    @include sp {
      position: static;
    }
    p {
      text-align: center;
    }
    .m-btn {
      button {
        margin: 20px auto 0;
        border: 0;
        display: block;
        height: 45px;
        line-height: 45px;
        width: 150px;
        font-weight: bold;
        color: #fff;
        font-size: 20px;
        color: #666;
        box-shadow: 0 10px 0 rgba(0, 0, 0, 0.4);
        background-color: #ccc;
        transition: .2s;
        &:hover {
          cursor: pointer;
          transform: translate(0,10px);
          box-shadow: none;
        }
        @include sp {
          width: 100%;
          height: 52px;
          font-size: 16px;
        }
      }
    }
  }
  &--btn {
    color: #fff;
    font-size: 16px;
    max-width: 360px;
    display: block;
    text-decoration: none;
    text-align: center;
    height: 50px;
    line-height: 50px;
    width: 100%;
    position: relative;
    background-color: $color-main;
    transition: 0.15s;
    margin: 0 auto;
    &:before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      position: absolute;
      background-color: #fff;
      right: 20px;
      top: calc(50% - 10px);
      @include sp {
        width: 16px;
        height: 16px;
        top: calc(50% - 8px);
        right: 10px;
      }
    }
    &:after {
      content: '';
      display: block;
      border-left: 1px solid $color-main;
      border-bottom: 1px solid $color-main;
      transform: rotate(-135deg);
      position: absolute;
      right: 28px;
      top: calc(50% - 4px);
      width: 6px;
      height: 6px;
      @include sp {
        right: 16px;
      }
    }
    @include sp {
      max-width: calc(100% - 40px);
      font-size: 12px;
      height: 40px;
      line-height: 40px;
    }
  }
}

.l-form_box {
  width: 100%;
  display: table;
  padding: 20px 0;
  border-bottom: 1px solid #B7B7B7;
  @include sp {
    display: block;
  }
  &-hidden {
    display: none;
  }
  &--heading {
    display: table-cell;
    width: 260px;
    font-size: 16px;
    line-height: 1.5;
    padding-right: 75px;
    vertical-align: middle;
    position: relative;
    @include sp {
      display: block;
      width: 100%;
      padding-right: 0;
    }
  }
  &--label {
    font-size: 13px;
    color: #666;
    position: absolute;
    right: 30px;
    padding: 2px 7px;
    border-radius: 3px;
    background: #eaeaea;
    @include sp {
      right: 0;
    }
    &-required {
      color: #fff;
      background: $color-main;
    }
  }
  &--input {
    display: table-cell;
    vertical-align: middle;
    @include sp {
      display: block;
      margin-top: 10px;
    }
    &-select {
      width: 50%;
      display: inline-block;
      position: relative;
      &:after {
        content: '';
        width: 6px;
        height: 6px;
        border-right: 2px solid #999;
        border-bottom: 2px solid #999;
        transform: rotate(45deg);
        position: absolute;
        top: 50%;
        right: 20px;
        margin-top: -5px;
      }
      @include sp {
        width: 100%;
      }
    }
    &-noclick {
      pointer-events: none;
      &:after {
        display: none;
      }
    }
    &-tri {
      padding: 0 10px;
      &:first-child {
        padding-left: 0;
      }
      @include sp {
        width: 90%;
        padding-left: 0;
        margin-bottom: 10px;
      }
    }
    &-day {
      width: 100%;
    }
    &-confirm {
      font-size: 16px;
    }
  }
  &--input_note {
    font-size: 14px;
    display: block;
    &-attention {
      color: #f00;
    }
  }
  &--tri_outer {
    display: flex;
    align-items: center;
    @include sp {
      flex-wrap: wrap;
    }
  }
  &--note {
    padding-top: 10px;
    display: block;
  }
  &--note_link {
    color: #3AA4AF;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  &--error {
    .form-error {
      display: block;
      font-size: 14px;
      line-height: 1.5;
      color: #d40000;
      margin-top: 5px;
      &:before {
        font-family: 'fa-solid';
        content: '\f06a';
        display: inline-block;
        margin-right: 3px;
      }
    }
  }
}

.l-form_box_list {
  list-style: none;
  &--item {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &--check_label {
    position: relative;
    margin-left: 10px;
    &:before {
      content: '';
      text-align: center;
      width: 18px;
      height: 18px;
      border: 2px solid #b5b5b5;
      display: inline-block;
      position: absolute;
      top: calc(50% - 11px);
      left: -30px;
      margin-right: 5px;
      line-height: 1;
    }
  }
  .m-input_checkbox {
    opacity: 0;
    &:checked {
      & + .l-form_box_list--check_label {
        &:before {
          content: '\2713';
          color: #fff;
          background: $color-main;
        }
      }
    }
  }
}

.l-form_pp {
  padding-top: 40px;
  @include sp {
    padding-top: 30px;
  }
  &--box {
    height: 200px;
    overflow: scroll;
    padding: 20px 30px;
    border: 1px solid #B7B7B7;
    background-color: #fff;
    @include sp {
      padding: 10px 20px;
    }
  }
  &--heading {
    font-size: 20px;
    text-align: center;
    line-height: 1.5;
    padding: 10px 0;
    background-color: $color-main;
    color: #fff;
    font-weight: normal;
    @include sp {
      font-size: 18px;
    }
  }
  &--txt {
    line-height: 1.7;
    @include sp {
      font-size: 14px;
    }
  }
  &--check {
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
    font-size: 16px;
    @include sp {
      padding-top: 20px;
      padding-bottom: 10px;
    }
  }
  &--checkbox {
    opacity: 0;
    &:checked {
      & + .l-form_pp--label {
        &:before {
          content: '\2713';
          color: #fff;
          background: $color-main;
        }
      }
    }
  }
  &--label {
    position: relative;
    &:before {
      content: '';
      width: 18px;
      height: 18px;
      border: 2px solid #b5b5b5;
      display: inline-block;
      position: absolute;
      top: calc(50% - 11px);
      left: -30px;
      margin-right: 5px;
      line-height: 1;
    }
  }
  &--error {
    .form-error {
      display: block;
      font-size: 14px;
      line-height: 1.5;
      color: #d40000;
      margin-top: 5px;
      &:before {
        font-family: 'fa-solid';
        content: '\f06a';
        display: inline-block;
        margin-right: 3px;
      }
    }
  }
}

.l-form_pp_list {
  padding-top: 15px;
  padding-bottom: 15px;
  &--heading {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 10px;
    @include sp {
      font-size: 16px;
    }
  }
  &--txt {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
    @include sp {
      font-size: 14px;
    }
  }
}

.l-form_pp_list_in {
  padding-top: 10px;
  padding-bottom: 10px;
  list-style: none;
  &--item {
    margin-bottom: 5px;
  }
}

.l-form_annotate_list {
  margin-top: 1em;
  margin-left: 1em;
  margin-right: 1em;
  &--item {
    padding-left: 1em;
    text-indent: -1em;
  }
}

.m-description {
  &--txt {
    font-size: 16px;
    line-height: 1.7;
    margin-bottom: 40px;
    @include sp {
      font-size: 14px;
      margin-bottom: 30px;
    }
  }
}
.m-description_list {
  &--heading {
    font-size: 20px;
    font-weight: $fw-bold;
    margin-bottom: 15px;
    line-height: 1.5;
    @include sp {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
  &--txt {
    font-size: 16px;
    line-height: 1.7;
    margin-bottom: 30px;
    @include sp {
      font-size: 14px;
      margin-bottom: 20px;
    }
  }
}
.m-description_list_in {
  padding-top: 15px;
  padding-bottom: 15px;
  @include sp {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  &--item {
    line-height: 1.7;
    margin-bottom: 5px;
  }
}
