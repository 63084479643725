.m-input_txt {
  font-size: 16px;
  padding: 0 20px;
  width: 100%;
  height: 60px;
  border: 2px solid #b5b5b5;
  border-radius: 3px;
  background-color: #fff;
  box-sizing: border-box;
}

@media screen and (max-width: 767px) {
  .m-input_txt {
    padding: 0 10px;
  }
}

.m-input_txt-other {
  width: 475px;
  height: 40px;
  margin-left: 10px;
}

@media screen and (max-width: 767px) {
  .m-input_txt-other {
    width: 100%;
    margin-top: 5px;
    margin-left: 0;
  }
}

.m-input_txtarea {
  font-size: 16px;
  padding: 10px 20px;
  box-sizing: border-box;
  width: 100%;
  border: 2px solid #b5b5b5;
  border-radius: 3px;
  background-color: #fff;
}

.m-select {
  width: 100%;
  color: #333;
  font-size: 16px;
  line-height: 1.5;
  padding: 15px 13px;
  border: 2px solid #b5b5b5;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #fff;
}

.m-section--ttl {
  font-size: 36px;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

@media screen and (max-width: 767px) {
  .m-section--ttl {
    font-size: 24px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.m-inner {
  width: 980px;
  box-sizing: border-box;
  padding: 0 15px;
  position: relative;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .m-inner {
    width: 100%;
  }
}

.m-main_visual {
  display: flex;
  height: 320px;
  margin-top: 94px;
}

@media screen and (max-width: 767px) {
  .m-main_visual {
    flex-wrap: wrap;
    height: auto;
    margin-top: 70px;
  }
}

.m-main_visual-explore .m-main_visual--bg {
  background-image: url("/course/images/bg-m-main-visual-explore.jpg");
}

.m-main_visual-explore .m-main_visual_about {
  background-image: url("/course/images/bg-m-main-visual-about-ptn-gr.png");
}

.m-main_visual-explore .m-main_visual_about--ttl {
  color: #39A06E;
}

.m-main_visual-break .m-main_visual--bg {
  background-image: url("/course/images/bg-m-main-visual-break.jpg");
}

.m-main_visual-break .m-main_visual_about {
  background-image: url("/course/images/bg-m-main-visual-about-ptn-or.png");
}

.m-main_visual-break .m-main_visual_about--ttl {
  color: #e28a35;
}

.m-main_visual-contact .m-main_visual--bg {
  background-image: url("/contact/images/bg-m-main-visual-contact.jpg");
}

.m-main_visual-contact .m-main_visual_about {
  background-image: url("/common/images/bg-m-main-visual-about-ptn-main.png");
}

.m-main_visual-contact .m-main_visual_about--ttl {
  color: #3AA4AF;
}

.m-main_visual-briefing .m-main_visual--bg {
  background-image: url("/briefing/images/bg-m-main-visual-briefing.jpg");
}

.m-main_visual-briefing .m-main_visual_about {
  padding-right: 170px;
  background-image: url("/common/images/bg-m-main-visual-about-ptn-main.png");
}

@media screen and (max-width: 767px) {
  .m-main_visual-briefing .m-main_visual_about {
    padding-top: 15px;
    padding-right: 140px;
  }
}

.m-main_visual-briefing .m-main_visual_about--ttl {
  font-size: 28px;
  color: #3AA4AF;
}

.m-main_visual-briefing .m-main_visual_about--ttl-single_line {
  min-height: 84px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .m-main_visual-briefing .m-main_visual_about--ttl {
    font-size: 18px;
  }
  .m-main_visual-briefing .m-main_visual_about--ttl-single_line {
    min-height: 64px;
  }
}

.m-main_visual-briefing .m-main_visual_about--ttl:after {
  border-width: 84px 30px 0 0;
}

@media screen and (max-width: 767px) {
  .m-main_visual-briefing .m-main_visual_about--ttl:after {
    border-width: 64px 20px 0 0;
  }
}

.m-main_visual-seminar .m-main_visual--bg {
  background-image: url("/briefing/images/bg-m-main-visual-briefing.jpg");
}

.m-main_visual-seminar .m-main_visual_about {
  padding-right: 170px;
  background-image: url("/common/images/bg-m-main-visual-about-ptn-main.png");
}

@media screen and (max-width: 767px) {
  .m-main_visual-seminar .m-main_visual_about {
    padding-top: 15px;
    padding-right: 140px;
  }
}

.m-main_visual-seminar .m-main_visual_about--ttl {
  color: #3AA4AF;
}

.m-main_visual-seminar .m-main_visual_about--ttl_main {
  padding-left: calc(50vw - 550px);
}

@media screen and (max-width: 767px) {
  .m-main_visual-seminar .m-main_visual_about--ttl_main {
    padding-left: 15px;
  }
}

.m-main_visual-interview {
  background: url("/interview/images/bg-m-main-visual-interview.jpg") no-repeat center center;
  background-size: cover;
  height: 271px;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .m-main_visual-interview {
    height: 145px;
  }
}

.m-main_visual-privacy {
  height: 100px;
  background: url("/common/images/bg-m-main-visual-about-ptn-main.png") repeat top center;
}

@media screen and (max-width: 767px) {
  .m-main_visual-privacy {
    height: 50px;
  }
}

.m-main_visual--bg {
  width: 50%;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;
  position: relative;
}

@media screen and (max-width: 767px) {
  .m-main_visual--bg {
    width: 100%;
    height: 65vw;
    order: 0;
  }
}

.m-main_visual_about {
  width: 50%;
  background-repeat: repeat;
  background-position: top left;
  padding-top: 115px;
  padding-right: 190px;
}

@media screen and (max-width: 767px) {
  .m-main_visual_about {
    width: 100%;
    padding: 30px 40px 15px 0;
    order: 1;
  }
}

.m-main_visual_about-break {
  padding-right: 110px;
}

@media screen and (max-width: 767px) {
  .m-main_visual_about-break {
    padding-right: 40px;
  }
}

.m-main_visual_about--ttl {
  font-size: 32px;
  font-weight: 600;
  position: relative;
  background: #fff;
  padding-right: 10px;
}

@media screen and (max-width: 767px) {
  .m-main_visual_about--ttl {
    font-size: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.m-main_visual_about--ttl:after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 48px 30px 0 0;
  border-color: #fff transparent transparent transparent;
  position: absolute;
  top: 0;
  right: -30px;
}

@media screen and (max-width: 767px) {
  .m-main_visual_about--ttl:after {
    border-width: 40px 20px 0 0;
    right: -20px;
  }
}

.m-main_visual_about--ttl_main {
  display: block;
  padding-left: calc(50vw - 500px);
}

@media screen and (max-width: 767px) {
  .m-main_visual_about--ttl_main {
    padding-left: 15px;
  }
}

.m-main_visual_about--ttl_sub {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  position: absolute;
  top: -35px;
  left: calc(50vw - 500px);
}

@media screen and (max-width: 767px) {
  .m-main_visual_about--ttl_sub {
    font-size: 14px;
    top: -25px;
    left: 15px;
  }
}

.m-course_info {
  padding-top: 25px;
  padding-bottom: 25px;
  background: #DDDDDD;
}

@media screen and (max-width: 767px) {
  .m-course_info {
    padding: 15px;
  }
}

.m-course_info--inner {
  max-width: 684px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.m-course_info--inner.single {
  justify-content: center;
}

.m-page_intro {
  padding-bottom: 55px;
}

@media screen and (max-width: 767px) {
  .m-page_intro {
    padding-bottom: 30px;
  }
}

.m-page_intro-explore .m-page_intro--ttl {
  color: #39A06E;
}

.m-page_intro-explore .m-page_intro--ttl_border {
  background: #39A06E;
}

.m-page_intro-explore .m-page_intro--lead:before {
  border-top-color: #39A06E;
  border-left-color: #39A06E;
}

.m-page_intro-explore .m-page_intro--lead:after {
  border-right-color: #39A06E;
  border-bottom-color: #39A06E;
}

.m-page_intro-break .m-page_intro--ttl {
  color: #e28a35;
}

.m-page_intro-break .m-page_intro--ttl_border {
  background: #e28a35;
}

.m-page_intro-break .m-page_intro--lead:before {
  border-top-color: #e28a35;
  border-left-color: #e28a35;
}

.m-page_intro-break .m-page_intro--lead:after {
  border-right-color: #e28a35;
  border-bottom-color: #e28a35;
}

.m-page_intro-contact {
  padding-top: 55px;
}

@media screen and (max-width: 767px) {
  .m-page_intro-contact {
    padding-top: 30px;
  }
}

.m-page_intro-contact .m-page_intro--ttl {
  color: #3AA4AF;
}

.m-page_intro-contact .m-page_intro--ttl_border {
  background: #3AA4AF;
}

.m-page_intro-contact .m-page_intro--lead:before {
  border-top-color: #3AA4AF;
  border-left-color: #3AA4AF;
}

.m-page_intro-contact .m-page_intro--lead:after {
  border-right-color: #3AA4AF;
  border-bottom-color: #3AA4AF;
}

.m-page_intro--ttl {
  font-size: 45px;
  text-align: center;
  position: relative;
}

@media screen and (max-width: 767px) {
  .m-page_intro--ttl {
    font-size: 20px;
  }
}

.m-page_intro--ttl_border {
  content: '';
  width: 110px;
  height: 136px;
  display: block;
  position: absolute;
  top: 0;
  left: 120px;
}

@media screen and (max-width: 767px) {
  .m-page_intro--ttl_border {
    left: 0;
    width: 40px;
    height: 60px;
  }
}

.m-page_intro--ttl_border:before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 136px 84px 0 0;
  border-color: #fff transparent transparent transparent;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 767px) {
  .m-page_intro--ttl_border:before {
    border-width: 60px 30px 0 0;
  }
}

.m-page_intro--ttl_border:after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 136px 84px;
  border-color: transparent transparent #fff transparent;
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (max-width: 767px) {
  .m-page_intro--ttl_border:after {
    border-width: 0 0 60px 30px;
  }
}

.m-page_intro--ttl_border-break {
  left: 40px;
}

@media screen and (max-width: 767px) {
  .m-page_intro--ttl_border-break {
    left: 0px;
  }
}

.m-page_intro--ttl_main {
  padding-top: 76px;
  display: block;
}

@media screen and (max-width: 767px) {
  .m-page_intro--ttl_main {
    padding-top: 36px;
  }
}

@media screen and (max-width: 767px) {
  .m-page_intro--ttl_main-break {
    padding-left: 20px;
  }
}

.m-page_intro--txt {
  max-width: 740px;
  margin: 0 auto;
  font-size: 16px;
  line-height: 2;
}

@media screen and (max-width: 767px) {
  .m-page_intro--txt {
    line-height: 1.7;
  }
}

.m-page_intro--txt-briefing {
  text-align: center;
  margin-bottom: 56px;
}

@media screen and (max-width: 767px) {
  .m-page_intro--txt-briefing {
    font-size: 14px;
    text-align: left;
    margin-bottom: 40px;
  }
}

.m-page_intro--txt-centering_without_sp {
  text-align: center;
}

@media screen and (max-width: 767px) {
  .m-page_intro--txt-centering_without_sp {
    text-align: left;
  }
}

.m-page_intro--txt-note {
  display: block;
  margin-top: 16px;
  font-size: 14px;
  line-height: 1.5;
}

@media screen and (max-width: 767px) {
  .m-page_intro--txt-note {
    font-size: 12px;
  }
}

.m-page_intro--txt > sup {
  font-size: 60%;
  position: relative;
  top: -5px;
}

.m-page_intro--lead {
  font-size: 20px;
  font-weight: 600;
  line-height: 2;
  text-align: center;
  max-width: 740px;
  margin: 35px auto 0;
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
}

@media screen and (max-width: 767px) {
  .m-page_intro--lead {
    font-size: 14px;
    line-height: 1.7;
    padding: 20px;
  }
}

.m-page_intro--lead:before {
  content: '';
  width: 100px;
  height: 77px;
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 767px) {
  .m-page_intro--lead:before {
    width: 60px;
    height: 40px;
  }
}

.m-page_intro--lead:after {
  content: '';
  width: 100px;
  height: 77px;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  position: absolute;
  right: 0;
  bottom: 0;
}

@media screen and (max-width: 767px) {
  .m-page_intro--lead:after {
    width: 60px;
    height: 40px;
  }
}

.m-page_intro--lead strong {
  font-size: 24px;
  color: #3AA4AF;
  padding-left: 1px;
  padding-right: 1px;
}

@media screen and (max-width: 767px) {
  .m-page_intro--lead strong {
    font-size: 16px;
  }
}

.m-page_intro--note {
  font-size: 16px;
  font-weight: normal;
  display: block;
  line-height: 1.7;
  margin-top: 10px;
}

@media screen and (max-width: 767px) {
  .m-page_intro--note {
    font-size: 13px;
    padding-top: 10px;
  }
}

.m-page_intro--link {
  text-decoration: underline;
  transition: .15s;
}

.m-page_intro--link:hover {
  color: #3AA4AF;
}

.m-form_section {
  padding-bottom: 60px;
  background: #F5F5F5;
}

.m-form_section--ttl {
  font-size: 36px;
  text-align: center;
  position: relative;
}

@media screen and (max-width: 767px) {
  .m-form_section--ttl {
    font-size: 20px;
  }
}

.m-form_section--ttl_border {
  content: '';
  width: 110px;
  height: 136px;
  display: block;
  background: #3AA4AF;
  position: absolute;
  top: 0;
  left: 120px;
}

@media screen and (max-width: 767px) {
  .m-form_section--ttl_border {
    left: 0;
    width: 40px;
    height: 60px;
  }
}

.m-form_section--ttl_border:before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 136px 84px 0 0;
  border-color: #F5F5F5 transparent transparent transparent;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 767px) {
  .m-form_section--ttl_border:before {
    border-width: 60px 30px 0 0;
  }
}

.m-form_section--ttl_border:after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 136px 84px;
  border-color: transparent transparent #F5F5F5 transparent;
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (max-width: 767px) {
  .m-form_section--ttl_border:after {
    border-width: 0 0 60px 30px;
  }
}

.m-form_section--ttl_main {
  padding-top: 86px;
  display: block;
}

@media screen and (max-width: 767px) {
  .m-form_section--ttl_main {
    padding-top: 36px;
  }
}

.l-form {
  margin-top: 40px;
  padding: 40px;
  background-color: #fff;
  position: relative;
  opacity: 0.2;
  pointer-events: none;
}

@media screen and (max-width: 767px) {
  .l-form {
    margin-top: 20px;
    padding: 20px 15px;
  }
}

.l-form.active {
  opacity: 1;
  pointer-events: auto;
}

.l-form-confirm .l-form--submit {
  margin-top: 60px;
}

@media screen and (max-width: 767px) {
  .l-form-confirm .l-form--submit {
    margin-top: 30px;
  }
}

.l-form--ttl {
  color: #3AA4AF;
  font-size: 24px;
  text-align: center;
  margin: 0 0 40px 0;
}

@media screen and (max-width: 767px) {
  .l-form--ttl {
    font-size: 18px;
    margin: 0 0 15px 0;
  }
}

.l-form--txt {
  font-size: 16px;
  line-height: 1.7;
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .l-form--txt {
    font-size: 14px;
    margin-bottom: 20px;
  }
}

.l-form--note {
  text-align: center;
  font-size: 16px;
  margin: 0 0 30px 0;
}

@media screen and (max-width: 767px) {
  .l-form--note {
    font-size: 12px;
    line-height: 1.3;
    margin: 0 0 15px 0;
  }
}

.l-form--submit {
  margin-top: 20px;
  margin-bottom: 20px;
}

.l-form--submit p {
  text-align: center;
}

.l-form--submit .m-btn button, .l-form--submit .m-btn input {
  margin: 20px auto 0;
  border: 0;
  display: block;
  height: 85px;
  width: 415px;
  font-weight: bold;
  font-size: 30px;
  color: #fff;
  box-shadow: 0 10px 0 #008093;
  background: #3AA4AF;
  transition: 0.2s;
  position: relative;
}

.l-form--submit .m-btn button:hover, .l-form--submit .m-btn input:hover {
  cursor: pointer;
  transform: translate(0, 10px);
  box-shadow: none;
}

.l-form--submit .m-btn button:before, .l-form--submit .m-btn input:before {
  content: "";
  display: block;
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  right: 20px;
  top: calc(50% - 15px);
}

@media screen and (max-width: 767px) {
  .l-form--submit .m-btn button:before, .l-form--submit .m-btn input:before {
    width: 20px;
    height: 20px;
    top: calc(50% - 10px);
    right: 15px;
  }
}

.l-form--submit .m-btn button:after, .l-form--submit .m-btn input:after {
  content: "";
  display: block;
  border-left: 3px solid #3AA4AF;
  border-bottom: 3px solid #3AA4AF;
  width: 8px;
  height: 8px;
  position: absolute;
  right: 31px;
  top: calc(50% - 6px);
  transform: rotate(-135deg);
}

@media screen and (max-width: 767px) {
  .l-form--submit .m-btn button:after, .l-form--submit .m-btn input:after {
    width: 6px;
    height: 6px;
    top: calc(50% - 5px);
    right: 22px;
  }
}

@media screen and (max-width: 767px) {
  .l-form--submit .m-btn button, .l-form--submit .m-btn input {
    width: 100%;
    height: 52px;
    font-size: 18px;
  }
}

.l-form--back {
  margin-top: 20px;
  margin-bottom: 20px;
  position: absolute;
  left: 50px;
  bottom: 70px;
}

@media screen and (max-width: 767px) {
  .l-form--back {
    position: static;
  }
}

.l-form--back p {
  text-align: center;
}

.l-form--back .m-btn button {
  margin: 20px auto 0;
  border: 0;
  display: block;
  height: 45px;
  line-height: 45px;
  width: 150px;
  font-weight: bold;
  color: #fff;
  font-size: 20px;
  color: #666;
  box-shadow: 0 10px 0 rgba(0, 0, 0, 0.4);
  background-color: #ccc;
  transition: .2s;
}

.l-form--back .m-btn button:hover {
  cursor: pointer;
  transform: translate(0, 10px);
  box-shadow: none;
}

@media screen and (max-width: 767px) {
  .l-form--back .m-btn button {
    width: 100%;
    height: 52px;
    font-size: 16px;
  }
}

.l-form--btn {
  color: #fff;
  font-size: 16px;
  max-width: 360px;
  display: block;
  text-decoration: none;
  text-align: center;
  height: 50px;
  line-height: 50px;
  width: 100%;
  position: relative;
  background-color: #3AA4AF;
  transition: 0.15s;
  margin: 0 auto;
}

.l-form--btn:before {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  background-color: #fff;
  right: 20px;
  top: calc(50% - 10px);
}

@media screen and (max-width: 767px) {
  .l-form--btn:before {
    width: 16px;
    height: 16px;
    top: calc(50% - 8px);
    right: 10px;
  }
}

.l-form--btn:after {
  content: '';
  display: block;
  border-left: 1px solid #3AA4AF;
  border-bottom: 1px solid #3AA4AF;
  transform: rotate(-135deg);
  position: absolute;
  right: 28px;
  top: calc(50% - 4px);
  width: 6px;
  height: 6px;
}

@media screen and (max-width: 767px) {
  .l-form--btn:after {
    right: 16px;
  }
}

@media screen and (max-width: 767px) {
  .l-form--btn {
    max-width: calc(100% - 40px);
    font-size: 12px;
    height: 40px;
    line-height: 40px;
  }
}

.l-form_box {
  width: 100%;
  display: table;
  padding: 20px 0;
  border-bottom: 1px solid #B7B7B7;
}

@media screen and (max-width: 767px) {
  .l-form_box {
    display: block;
  }
}

.l-form_box-hidden {
  display: none;
}

.l-form_box--heading {
  display: table-cell;
  width: 260px;
  font-size: 16px;
  line-height: 1.5;
  padding-right: 75px;
  vertical-align: middle;
  position: relative;
}

@media screen and (max-width: 767px) {
  .l-form_box--heading {
    display: block;
    width: 100%;
    padding-right: 0;
  }
}

.l-form_box--label {
  font-size: 13px;
  color: #666;
  position: absolute;
  right: 30px;
  padding: 2px 7px;
  border-radius: 3px;
  background: #eaeaea;
}

@media screen and (max-width: 767px) {
  .l-form_box--label {
    right: 0;
  }
}

.l-form_box--label-required {
  color: #fff;
  background: #3AA4AF;
}

.l-form_box--input {
  display: table-cell;
  vertical-align: middle;
}

@media screen and (max-width: 767px) {
  .l-form_box--input {
    display: block;
    margin-top: 10px;
  }
}

.l-form_box--input-select {
  width: 50%;
  display: inline-block;
  position: relative;
}

.l-form_box--input-select:after {
  content: '';
  width: 6px;
  height: 6px;
  border-right: 2px solid #999;
  border-bottom: 2px solid #999;
  transform: rotate(45deg);
  position: absolute;
  top: 50%;
  right: 20px;
  margin-top: -5px;
}

@media screen and (max-width: 767px) {
  .l-form_box--input-select {
    width: 100%;
  }
}

.l-form_box--input-noclick {
  pointer-events: none;
}

.l-form_box--input-noclick:after {
  display: none;
}

.l-form_box--input-tri {
  padding: 0 10px;
}

.l-form_box--input-tri:first-child {
  padding-left: 0;
}

@media screen and (max-width: 767px) {
  .l-form_box--input-tri {
    width: 90%;
    padding-left: 0;
    margin-bottom: 10px;
  }
}

.l-form_box--input-day {
  width: 100%;
}

.l-form_box--input-confirm {
  font-size: 16px;
}

.l-form_box--input_note {
  font-size: 14px;
  display: block;
}

.l-form_box--input_note-attention {
  color: #f00;
}

.l-form_box--tri_outer {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .l-form_box--tri_outer {
    flex-wrap: wrap;
  }
}

.l-form_box--note {
  padding-top: 10px;
  display: block;
}

.l-form_box--note_link {
  color: #3AA4AF;
  cursor: pointer;
}

.l-form_box--note_link:hover {
  text-decoration: underline;
}

.l-form_box--error .form-error {
  display: block;
  font-size: 14px;
  line-height: 1.5;
  color: #d40000;
  margin-top: 5px;
}

.l-form_box--error .form-error:before {
  font-family: 'fa-solid';
  content: '\f06a';
  display: inline-block;
  margin-right: 3px;
}

.l-form_box_list {
  list-style: none;
}

.l-form_box_list--item {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 15px;
}

.l-form_box_list--item:last-child {
  margin-bottom: 0;
}

.l-form_box_list--check_label {
  position: relative;
  margin-left: 10px;
}

.l-form_box_list--check_label:before {
  content: '';
  text-align: center;
  width: 18px;
  height: 18px;
  border: 2px solid #b5b5b5;
  display: inline-block;
  position: absolute;
  top: calc(50% - 11px);
  left: -30px;
  margin-right: 5px;
  line-height: 1;
}

.l-form_box_list .m-input_checkbox {
  opacity: 0;
}

.l-form_box_list .m-input_checkbox:checked + .l-form_box_list--check_label:before {
  content: '\2713';
  color: #fff;
  background: #3AA4AF;
}

.l-form_pp {
  padding-top: 40px;
}

@media screen and (max-width: 767px) {
  .l-form_pp {
    padding-top: 30px;
  }
}

.l-form_pp--box {
  height: 200px;
  overflow: scroll;
  padding: 20px 30px;
  border: 1px solid #B7B7B7;
  background-color: #fff;
}

@media screen and (max-width: 767px) {
  .l-form_pp--box {
    padding: 10px 20px;
  }
}

.l-form_pp--heading {
  font-size: 20px;
  text-align: center;
  line-height: 1.5;
  padding: 10px 0;
  background-color: #3AA4AF;
  color: #fff;
  font-weight: normal;
}

@media screen and (max-width: 767px) {
  .l-form_pp--heading {
    font-size: 18px;
  }
}

.l-form_pp--txt {
  line-height: 1.7;
}

@media screen and (max-width: 767px) {
  .l-form_pp--txt {
    font-size: 14px;
  }
}

.l-form_pp--check {
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  font-size: 16px;
}

@media screen and (max-width: 767px) {
  .l-form_pp--check {
    padding-top: 20px;
    padding-bottom: 10px;
  }
}

.l-form_pp--checkbox {
  opacity: 0;
}

.l-form_pp--checkbox:checked + .l-form_pp--label:before {
  content: '\2713';
  color: #fff;
  background: #3AA4AF;
}

.l-form_pp--label {
  position: relative;
}

.l-form_pp--label:before {
  content: '';
  width: 18px;
  height: 18px;
  border: 2px solid #b5b5b5;
  display: inline-block;
  position: absolute;
  top: calc(50% - 11px);
  left: -30px;
  margin-right: 5px;
  line-height: 1;
}

.l-form_pp--error .form-error {
  display: block;
  font-size: 14px;
  line-height: 1.5;
  color: #d40000;
  margin-top: 5px;
}

.l-form_pp--error .form-error:before {
  font-family: 'fa-solid';
  content: '\f06a';
  display: inline-block;
  margin-right: 3px;
}

.l-form_pp_list {
  padding-top: 15px;
  padding-bottom: 15px;
}

.l-form_pp_list--heading {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .l-form_pp_list--heading {
    font-size: 16px;
  }
}

.l-form_pp_list--txt {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .l-form_pp_list--txt {
    font-size: 14px;
  }
}

.l-form_pp_list_in {
  padding-top: 10px;
  padding-bottom: 10px;
  list-style: none;
}

.l-form_pp_list_in--item {
  margin-bottom: 5px;
}

.l-form_annotate_list {
  margin-top: 1em;
  margin-left: 1em;
  margin-right: 1em;
}

.l-form_annotate_list--item {
  padding-left: 1em;
  text-indent: -1em;
}

.m-description--txt {
  font-size: 16px;
  line-height: 1.7;
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .m-description--txt {
    font-size: 14px;
    margin-bottom: 30px;
  }
}

.m-description_list--heading {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
  line-height: 1.5;
}

@media screen and (max-width: 767px) {
  .m-description_list--heading {
    font-size: 16px;
    margin-bottom: 10px;
  }
}

.m-description_list--txt {
  font-size: 16px;
  line-height: 1.7;
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .m-description_list--txt {
    font-size: 14px;
    margin-bottom: 20px;
  }
}

.m-description_list_in {
  padding-top: 15px;
  padding-bottom: 15px;
}

@media screen and (max-width: 767px) {
  .m-description_list_in {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.m-description_list_in--item {
  line-height: 1.7;
  margin-bottom: 5px;
}
